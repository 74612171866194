var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"bg-white px-0",attrs:{"id":"page-topbar"}},[_c('div',{staticClass:"navbar-header container-fluid"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"navbar-brand-box"},[_c('div',{staticClass:"logo"},[(_vm.currentUser.protocol == 'http://')?_c('span',{},[_c('img',{attrs:{"src":`${_vm.currentUser.protocol}${_vm.current_domain}${_vm.currentUser.company_logo_url}`,"alt":"","height":"36","width":"202"}})]):_c('span',{},[_c('img',{attrs:{"src":`${_vm.currentUser.company_logo_url}`,"alt":"","height":"36","width":"202"}})])])]),_c('button',{staticClass:"btn btn-sm px-3 font-size-16 d-lg-none header-item",attrs:{"type":"button","data-toggle":"collapse","data-target":"#topnav-menu-content"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-fw fa-bars"})])]),_c('div',{staticClass:"d-flex"},[_c('simplebar',{staticStyle:{"max-height":"230px"},attrs:{"data-simplebar":""}}),_c('b-dropdown',{staticClass:"d-inline-block",attrs:{"toggle-class":"header-item","right":"","variant":"white"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticClass:"rounded-circle header-profile-user",attrs:{"src":_vm.currentUser.avatar_img ? _vm.currentUser.avatar_img_link : _vm.currentUser.avatar_img_link,"alt":"Avatar"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-1 fw-medium font-size-15"},[_vm._v(_vm._s(_vm.currentUser.full_name))]),_c('i',{staticClass:"uil-angle-down d-none d-xl-inline-block font-size-15"})]},proxy:true}])},[(_vm.currentUser.type == 'restaurant_owner' ||
            _vm.currentUser.type == 'manager'
            )?_c('router-link',{staticClass:"dropdown-item d-none",attrs:{"to":"/my-profile"}},[_c('i',{staticClass:"uil uil-user-circle font-size-18 align-middle text-muted me-1"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("navbar.dropdown.marcus.list.profile")))])]):_c('router-link',{staticClass:"dropdown-item d-none",attrs:{"to":"/employee-profile"}},[_c('i',{staticClass:"uil uil-user-circle font-size-18 align-middle text-muted me-1"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("navbar.dropdown.marcus.list.profile")))])]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript: void(0);"},on:{"click":_vm.logoutUser}},[_c('i',{staticClass:"uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("navbar.dropdown.marcus.list.logout")))])])],1)],1)]),(_vm.currentUser.is_phone_verified == 0)?_c('div',{staticClass:"alert alert-warning mb-0 p-2 text-center"},[_vm._v(" Verify your phone number to receive important SMS notifications related to your work from us! "),_c('router-link',{attrs:{"to":{
      name: 'verify-phone-number',
      query: {
        phone: this.currentUser.phone,
        token: this.currentUser.token,
      },
    }}},[_c('u',[_vm._v(" Click Here")])])],1):_vm._e(),_c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"topnav rounded-0 position-relative"},[_c('nav',{staticClass:"navbar navbar-light navbar-expand-lg topnav-menu"},[_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"topnav-menu-content"}},[_c('ul',{staticClass:"navbar-nav"},_vm._l((_vm.getMenuitems),function(item,index){return _c('li',{key:index,staticClass:"nav-item dropdown"},[(!item.subItems)?_c('router-link',{staticClass:"nav-link dropdown-toggle arrow-none side-nav-link-ref",attrs:{"to":item.link}},[_c('i',{class:`${item.icon} me-2`}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_vm._e(),(item.subItems)?_c('a',{staticClass:"nav-link dropdown-toggle arrow-none",attrs:{"href":"javascript: void(0);","id":"topnav-components","role":"button"},on:{"click":_vm.onMenuClick}},[_c('i',{class:`${item.icon} me-2`}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),_c('div',{staticClass:"arrow-down"})]):_vm._e(),(_vm.hasItems(item))?_c('div',{staticClass:"dropdown-menu",class:{
                'dropdown-mega-menu-xl px-2': item.subItems.length > 11,
              },attrs:{"aria-labelledby":"topnav-dashboard"}},[_vm._l((item.subItems),function(subitem,index){return [(item.subItems.length < 11 && !_vm.hasItems(subitem))?_c('router-link',{key:index,staticClass:"col dropdown-item side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(item.subItems.length > 11)?_c('div',{key:index},[(index % 3 == 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('router-link',{staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(item.subItems[index].label)))])],1),(item.subItems[index + 1])?_c('div',{staticClass:"col-lg-4"},[_c('router-link',{staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":item.subItems[index + 1].link}},[_vm._v(_vm._s(_vm.$t(item.subItems[index + 1].label)))])],1):_vm._e(),(item.subItems[index + 2])?_c('div',{staticClass:"col-lg-4"},[_c('router-link',{staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":item.subItems[index + 2].link}},[_vm._v(_vm._s(_vm.$t(item.subItems[index + 2].label)))])],1):_vm._e()]):_vm._e()]):_vm._e(),(_vm.hasItems(subitem))?_c('div',{key:index,staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript: void(0);"},on:{"click":_vm.onMenuClick}},[_vm._v(" "+_vm._s(_vm.$t(subitem.label))+" "),_c('div',{staticClass:"arrow-down"})]),_c('div',{staticClass:"dropdown-menu"},_vm._l((subitem.subItems),function(subSubitem,index){return _c('router-link',{key:index,staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])}),1)]):_vm._e()]})],2):_vm._e()],1)}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }