export const menuItems = [
 
    {
        id: 1,
        label: "Dashboard",
        icon: "uil-home-alt",
        link: "/employee-home"
    },
 
    {
        id: 2,
        label: "Create Your Schedule",
        icon: "uil-calender",
        link: "/employee-schedule"
    },
    {
        id: 3,
        label: "Shifts Listing",
        icon: "mdi mdi-pencil-minus-outline",
        link: "/shift-listing"
    },
 
    // {
    //     id: 4,
    //     label: "My Profile",
    //     icon: "uil-user",
    //     link: "/employee-profile"
    // },
    // {
    //     id: 5,
    //     label: "Feedback",
    //     icon: "uil-clipboard-notes",
    //     link: "/feedback"
    // },

    // {
    //     id: 6,
    //     label: "Tickets",
    //     icon: "uil-clipboard-notes",
    //     link: "/tickets"
    // },
    
]